var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{attrs:{"type":"dark","variant":"dark","fixed":"top"}},[_c('b-navbar-brand',{staticStyle:{"position":"absolute","left":"50%","transform":"translateX(-50%)"},attrs:{"to":"/home"}},[_vm._v(" 怡信企业年报通 ")]),_c('b-navbar-nav',[_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm","to":{
          name: 'userBoardsHome',
          params: { username: _vm.user.username },
        }}},[_c('b-icon',{attrs:{"icon":"house-fill"}})],1)],1),_c('b-dropdown',{staticStyle:{"position":"absolute","right":"1rem"},attrs:{"no-caret":"","right":"","menu-class":"mt-3"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.user.username.substring(0, 1).toUpperCase())+" ")]},proxy:true}])},[_c('b-dropdown-text',[_vm._v(" "+_vm._s(_vm.user.username)+" ")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{
          name: 'user-profile',
          params: { username: _vm.user.username },
        }}},[_vm._v(" 个人文件 ")]),_c('b-dropdown-item',{attrs:{"to":{
          name: 'user-activity',
          params: { username: _vm.user.username },
        }}},[_vm._v(" 工作区 ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'user-cards', params: { username: _vm.user.username } }}},[_vm._v(" 工作卡片 ")]),_c('b-dropdown-item',{attrs:{"to":{
          name: 'user-account',
          params: { username: _vm.user.username },
        }}},[_vm._v(" 设置 ")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{ name: 'logout' }}},[_vm._v("退出")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }