<template>
  <div class="wrapper mx-auto my-5 text-center">
    <b-form
      @submit.prevent="onsubmit"
      class="form my-5 p-3 border-secondary shadow"
    >
      <h4 class="mb-3">注册 怡信企业年报通</h4>
      <b-form-input
        class="my-3"
        type="email"
        required
        placeholder="输入email"
        v-model="email"
        :state="state"
        @focus="state = null"
      >
      </b-form-input>

      <b-form-invalid-feedback :state="state">
        用户已经存在
      </b-form-invalid-feedback>

      <b-form-input
        class="my-3"
        type="username"
        required
        placeholder="输入username"
        v-model="username"
      >
      </b-form-input>

      <b-button
        type="submit"
        class="my-3"
        block
        variant="success"
      >
        完成
      </b-button>

      <hr />
      <b-link :to="{ name: 'home' }" style="margin-right:4rem">返回</b-link>
      <b-link style="margin-left:4rem" :to="{ name: 'login' }">已经注册，立即登入</b-link>
    </b-form>
  </div>
</template>

<script>
import { uid } from '@/utils/utils';
export default {
  name: "SignUp",
  data() {
    return {
      email: this.$router.params.email || "",
      username:"",
      state: null,
    };
  },
  methods: {
    onsubmit() {
      if (!this.email || !this.username) {
        this.state = null;
        return;
      }

      if(localStorage.getItem(this.email)){
        //   如果这个email已经被注册
        this.state = false
        return
      }

      this.state = null;
      this.$store.commit("SIGNUP_USER", { 
          user:{
                id:uid(),
                email: this.email ,
                username:this.username,
                boards:[],
          }
    });

      this.$router.push({
        name: "userBoardsHome",
        params: { username: this.username },
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 400px;
}

.footer {
  bottom: 0px;

  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
