<template>
  <div>
    <navbar />
    <div class="text-center" style="margin-top: 5rem">
      <h3>Page not found.</h3>
      <p>
        This page may be private.
      </p>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/NavBar.vue';
export default {
  components: {
    Navbar
  }
};
</script>

<style scoped></style>
