<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import appInfo, { system } from './utils/config'
export default {
  name: 'App',
  created () {
    console.log(appInfo);
    window.document.title = appInfo[system].company
  },
  mounted () {
    const clientWidth = document.documentElement.clientWidth
    console.log('clientWidth', clientWidth);
    this.$store.commit('SET_CLIENTWIDTH', clientWidth)
  }
}
</script>

<style>
/* 阿里字体图标设置 */
.icon,
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>
