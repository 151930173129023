import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';
import './assets/index.css';
import './assets/icon/iconfont.css';

// Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
