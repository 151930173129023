<template>
  <div class="wrapper mx-auto my-5 text-center">
    <b-form
      @submit.prevent="onsubmit"
      class="form my-5 p-3 border-secondary shadow"
    >
      <h4 class="mb-3">登入 怡信企业年报通</h4>
      <b-form-input
        class="my-3"
        type="email"
        required
        placeholder="输入email"
        v-model="email"
        :state="state"
        @focus="state = null"
      >
      </b-form-input>
      <b-form-invalid-feedback :state="state">
        用户不存在
      </b-form-invalid-feedback>

      <b-button :to="{ name: 'home' }" href="#" variant="success" class="mx-5"
        >免费注册</b-button
      >

      <b-button
        type="submit"
        class="my-3 mx-1"
        block
        :disabled="!email"
        variant="success"
      >
        登陆
      </b-button>
      <hr />
      <b-link :to="{ name: 'home' }">返回</b-link>

      <!-- <b-link :to="{ name: 'home' }">注册用户</b-link> -->
    </b-form>
  </div>
</template>

<script>
export default {
  name: "UserLogin",
  data() {
    return {
      email: "",
      state: null,
    };
  },
  methods: {
    onsubmit() {
      if (!this.email) {
        this.state = null;
        return;
      }

      let user = JSON.parse(localStorage.getItem(this.email));
      if (!user) {
        this.state = false;
        return;
      }

      this.state = null;
      this.$store.commit("LOGIN_USER", { email: user.email });

      this.$router.push({
        // name: "user-boards",
        // params: { username: user.username },
        name: "home",
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 400px;
}

.footer {
  bottom: 0px;

  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
