<template>
  <div>
    <Navbar />
    <div class="content w-100 text-center">
      <div
        class="box d-flex flex-column align-items-center justify-content-center w-100"
      >
        <h1>感谢使用怡信企业年报通</h1>
        <p>您已经退出登陆，请继续下一步……</p>
      </div>
      <div
        class="box d-flex flex-column align-items-center justify-content-center w-100"
      >
        <h1>
          开始操作？
        </h1>
        <p>
          我们为你下次登录保存了一些偏好，但是如果你愿意，现在就可以使用了。  
        </p>
        <b-button>
          删除保存首选项
        </b-button>
      </div>
      <div
        class="box d-flex flex-column align-items-center justify-content-center w-100"
      >
        <p>
          <strong>是否下载了APP？</strong> 我有iPhone iPad的应用程序  Android手机、平板电脑和手表。 你可能有一个。  
        </p>
        <b-button to="/platforms">下载APP</b-button>
      </div>
      <div
        class="box d-flex flex-column align-items-center justify-content-center w-100 "
      >
        <h1>跟随进入</h1>
        <p>
          更多……
          <b-link href="https://github.com/zanqwq" class="text-muted"
            >github</b-link
          >
        </p>
      </div>
      <div
        class="box d-flex flex-column align-items-center justify-content-center w-100"
      >
        <h1>
          分享给更多的朋友!
        </h1>
        <p>
          帮我宣传一下，交些朋友
        </p>
      </div>
      <div
        class="box d-flex flex-column align-items-center justify-content-center w-100"
      >
        <h1>
          “感谢使用怡信企业年报通”
        </h1>
        <h1 class="mt-1">再见！</h1>
      </div>
    </div>
    <Footer class="mt-5"></Footer>
  </div>
</template>

<script>
import Navbar from "../components/NavBar.vue";
import Footer from "../components/MyFooter.vue";
export default {
  components: {
    Navbar,
    Footer
  }
};
</script>

<style scoped>
.content {
  margin-top: 55.87px;
}

.box {
  height: 300px;
}

.box p {
  max-width: 400px;
}

.box:nth-of-type(1) {
  background: white;
}

.box:nth-of-type(2) {
  background: #fdfae5;
}

.box:nth-of-type(3) {
  background: #bcd9ea;
}

.box:nth-of-type(4) {
  background: white;
}

.box:nth-of-type(5) {
  background: #f5ea92;
}

.box:nth-of-type(6) {
  color: white;
  background: #0079bf;
}
</style>
