<template>
  <div>
    <b-navbar type="dark" variant="dark" fixed="top">
      <b-navbar-brand
        to="/home"
        style="position: absolute; left: 50%; transform: translateX(-50%)"
      >
       怡信企业年报通
      </b-navbar-brand>

      <b-navbar-nav>
        <b-button
          size="sm"
          class="ml-1"
          :to="{
            name: 'userBoardsHome',
            params: { username: user.username },
          }"
        >
          <b-icon icon="house-fill"></b-icon>
        </b-button>
      </b-navbar-nav>

      <b-dropdown
        no-caret
        right
        menu-class="mt-3"
        style="position: absolute; right: 1rem;"
      >
        <template #button-content>
          {{ user.username.substring(0, 1).toUpperCase() }}
        </template>

        <b-dropdown-text>
          {{ user.username }}
        </b-dropdown-text>

        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          :to="{
            name: 'user-profile',
            params: { username: user.username },
          }"
        >
          个人文件
        </b-dropdown-item>
        <b-dropdown-item
          :to="{
            name: 'user-activity',
            params: { username: user.username },
          }"
        >
          工作区
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'user-cards', params: { username: user.username } }"
        >
          工作卡片
        </b-dropdown-item>
        <b-dropdown-item
          :to="{
            name: 'user-account',
            params: { username: user.username },
          }"
        >
          设置
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item :to="{ name: 'logout' }">退出</b-dropdown-item>
      </b-dropdown>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HomeHeader",
  computed: {
    ...mapState(["user"]),
    hasLogin() {
      return this.user;
    },
  },
};
</script>


<style lang="less" scoped>
</style>